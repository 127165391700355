import $ from 'jquery';  
window.jQuery = $; window.$ = $;

require("@rails/ujs").start()

import 'blueimp-file-upload/js/jquery.iframe-transport';
import 'blueimp-file-upload/js/jquery.fileupload';


(function( $ ) {
    
  $.fn.swap_icon_for_image = function() {
    return this.each(function() {	    
      var c = $(this), 
      src = c.data('src');
      if (src != '') {
        $('<img />').attr('src', src).on('load', function(){
          c.replaceWith($(this));
        });
      }
    });
  },
  
  $.fn.toogle_fileupload = function() {
    return this.each(function() {	    
      var c = $(this),
          n = parseInt(c.data('n'), 10),
          files = $('.attachment:visible', c);
      if (files.length < n) {
        $('#fileupload-container').show();
      } else {
        $('#fileupload-container').hide();
      }
    });
  }
   
})( jQuery );




$(function(){
  
  $("#files").toogle_fileupload();
  
  var form = $("#fileupload").parents('form:first');
  var save_btn = $('button[type=submit]', form);
       
  $("#fileupload").fileupload({
    dataType: "json",
    type: 'PUT',
    add: function(e, data) {
      
      
      save_btn.attr('disabled' , true);
      
      data.context = $('<div class="attachment file wrapper">')
        .append($('<a target="_blank">').text(data.files[0].name))
        .appendTo("#files");
      data.submit();
    },
    progress: function(e, data) {
      var progress = parseInt((data.loaded / data.total) * 100, 10);
      data.context.css("background-position-x", 100 - progress + "%");
    },
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    done: function(e, data) {      
      
      if ($('#fileupload').fileupload('active') < 2) {
        save_btn.attr('disabled' , false);
      }
      
      data.context
        .addClass("done")        
        .append('<div>'+data.result.files[0].dim+'<br/>'+data.result.files[0].type+'<br/>'+data.result.files[0].size+'</div>')
        .append('<button class="delete_field">'+data.result.files[0].remove+'</button>')
        .append('<input type="hidden" name="blob_keys[]" value="'+data.result.files[0].key+'" />')      
        .find("a")
        .prop("href", data.result.files[0].url)
        .html('<i data-src="'+data.result.files[0].src+'">insert_photo</i>')
        
      data.context.find("i[data-src]").swap_icon_for_image();
      $("#files").toogle_fileupload();
      
    }
  });
  
  // All external links - new tab
  $('a')
	  .filter('[href^="http"], [href^="//"]')
	  .not('[href*="' + window.location.host + '"]')
	  .attr('rel', 'noopener noreferrer')
	  .attr('target', '_blank');
  
	/* Remove field */
	$('body').on('click', '.remove_field', function(e){
    var l = $(this);

    l.prev("input[type=hidden]").val("1");
    l.parents(".wrapper:first").hide();      
    
		e.preventDefault();
	});
  
	$('body').on('click', '.delete_field', function(e){
    var l = $(this);

    l.parents(".wrapper:first").remove();      
    
		e.preventDefault();
	});
  
  /* flash */
  $('.flash').each(function(){
    var f = $(this).show(), t = f.height();
    
    f.css({ 'margin-left': -(f.width()/2), 'top': -f.height()})
      .delay(600)
      .animate({
        top: 0
      }, 400)
      .delay(3000)
      .animate({
        top: -t
      }, 600);
  });
  
  
  // $('header nav ul>li>a').on('click', function(e){
  //   $(this).parent().toggleClass('open').siblings('.open').removeClass('open');
  //   if ($(this).siblings().length>0) {
  //     e.preventDefault();
  //   }      
  // });
  // 
  // // Menu
  // $("header>button").on('click', function(){
  //   $(this).parent().toggleClass('open');
  // });
  
  
  
  // $("input[name='work[is_privately_owned]']").on('change', function(e){
  //   if ($(this).val() == '1') {
  //     $("#work_price_str").val('').attr('disabled', 'disabled');
  //   } else {
  //     $("#work_price_str").attr('disabled', null);
  //   }
  // });
  
  
  
  
  /* Textarea char count */
  $("textarea[maxlength]").each(function(){
    var ta = $(this);
        
    var status = $("<span class='chars-left'>"+(parseInt(ta.attr('maxlength'),10) - ta.val().length)+' '+ta.data('text')+"</span>").insertBefore(ta);    
    
    ta.on('keyup', function() {
      var text_length = ta.val().length;
      var text_remaining = parseInt(ta.attr('maxlength'),10) - text_length;

      status.html(text_remaining + ' ' + ta.data('text'));
    });
    
  });
  
  
  // Other category
  $("#work_category").on('change', function(e){
    var $this = $(this);    
    $('#work_category_alt').prop('disabled', ($this.prop('selectedIndex') != ($('option', $this).length-1)));    
    
    $this.parents('form').attr('class', "cat-"+$this.val().toLowerCase());
  });
  
  // $("#applicant_how_have_you_heard").on('change', function(e){
  //   var $this = $(this);    
  //   $('#applicant_how_have_you_heard_alt').prop('disabled', ($this.prop('selectedIndex') != ($('option', $this).length-1)));    
  //   
  //   $this.parent().attr('class', "val-"+$this.val());
  // });

  
  
  $(".toggle-next input[type=checkbox]").on('change', function(e){
    var $this = $(this),
        p= $this.parent();
    
    $(this).get(0).checked ? p.addClass('on') : p.removeClass('on');
  })
  
  
});

